///////////////////////////////////////////////////////////
// File        : SectionWithCards.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./SectionWithCards.scss";
import CardIconLeft from "~/Components/Items/CardIconLeft/CardIconLeft";

// Function Definition
function SectionWithCards(props) {
  // console.info("SectionWithCards.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [title, setTitle] = useState("");
  const [cards, setCards] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            fields.title && setTitle(fields.title);
            if (fields.cards && Array.isArray(fields.cards)) {
              setCards(fields.cards.map((item, index) => (
                <div className="item col-12 col-md-6" key={item.sys.id}>
                  <CardIconLeft id={item.sys.id} />
                </div>
              )));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error}  [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="sectionWithCards">
      <div className="container">
        <h3 className="mb-4 text-center section-title single-col-max mx-auto">{title}</h3>
        <div className="row">
          {cards}
        </div>
      </div>
    </div>
  );
}

// Interface
SectionWithCards.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
SectionWithCards.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default SectionWithCards;

