///////////////////////////////////////////////////////////
// File        : HeroWrapper.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps, { extendedText } from "~/Components/api/ComponentProps";
import "./HeroWrapper.scss";
import Header from "~/Components/Layouts/Header/Header";
import Link from "~/Components/Elements/Link/Link";
import HeroImage from "~/Components/Items/HeroImage/HeroImage";

// Function Definition
function HeroWrapper(props) {
  // console.info("HeroWrapper.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [header, setHeader] = useState("");
  const [headline, setHeadline] = useState("");
  const [tagline, setTagline] = useState("");
  const [callToAction, setCallToAction] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.header && setHeader(<Header id={fields.header.sys.id} />);
            fields.headline && setHeadline(fields.headline);
            // fields.tagline && setTagline(fields.tagline);
            // fields.taglineExt && fields.taglineExt.content && fields.taglineExt.content.length && fields.taglineExt.content[0].content && fields.taglineExt.content[0].content.length && setTagline(fields.taglineExt.content[0].content[0].value); // Override 'tagline', if necessary
            (fields.tagline || fields.taglineExt) && setTagline(extendedText(fields.tagline, fields.taglineExt));
            fields.callToAction && setCallToAction(<Link id={fields.callToAction.sys.id} className="btn btn-gradient mr-2 mb-3" />);
            fields.heroImage && setHeroImage(<HeroImage id={fields.heroImage.sys.id} />);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="hero-wrapper heroWrapper">
      <div className="curve-container">
        <div className="curve-top"></div>
        <div className="curve-bottom"></div>
      </div>
      {header}
      <section className="hero-section over-curve pt-5 py-md-5">
        <div className="container">
          <div className="hero-content-holder z-index-10 position-relative">
            <h1 className="site-headline mb-3" dangerouslySetInnerHTML={{__html: headline.replace(/\\n/g, "<br/>")}} />
            <div className="site-tagline mb-4" dangerouslySetInnerHTML={{__html: tagline.replace(/\\n/g, "<br/>")}} />
            <div className="cta-btns mb-lg-3">
              {callToAction}
            </div>
          </div>
        </div>
        {heroImage}
      </section>
      <section className="logos-section theme-bg-primary over-curve pb-4 py-lg-5 text-center">
        <div className="container">
          <div className="logos-row row mx-auto"> 
          </div>    
        </div>
      </section>
    </div>
  );
}

// Interface
HeroWrapper.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
HeroWrapper.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default HeroWrapper;

