///////////////////////////////////////////////////////////
// File        : CallToActionSection.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./CallToActionSection.scss";
import DataBox from "~/Components/Layouts/DataBox/DataBox";

// Function Definition
function CallToActionSection(props) {
  // console.info("CallToActionSection.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [dataBox, setDataBox] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            fields.title && setTitle(fields.title);
            fields.intro && setIntro(fields.intro);
            fields.dataBox && setDataBox(<DataBox id={fields.dataBox.sys.id} />);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <section className="cta-section text-center py-5 theme-bg-gradient position-relative callToActionSection">
      <div className="curve-containers">
        <div className="curve-top"></div>
        <div className="curve-bottom"></div>
      </div>
      <div className="container py-4">
        <h3 className="section-title text-white mb-3">{title}</h3>
        <div className="section-intro mb-5 text-white single-col-max mx-auto">{intro}</div>
        {dataBox}
        <div className="pt-3 text-center">
          <a className="btn btn-inverse" href="signup.html">Start Free Trial</a>
        </div>
      </div>
    </section>
  );
}

// Interface
CallToActionSection.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
CallToActionSection.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default CallToActionSection;

