import React, { useRef, useEffect } from "react";

export const space = "8faia9v2n4rs";
// export const env = "master";
// export const env = "test";
export const env = "a2a";
export const token = "_gVqJX5mOSOOgRE877iPTLmNXTKUKcG3SAEwoG1-PHI";
// export const endpoint = `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${env}`; // GraphQL endpoint
export const endpoint = `https://cdn.contentful.com/spaces/${space}/environments/${env}`; // Contentful endpoint
// export const endpoint = `https://aboleyn.herokuapp.com/spaces/${space}/environments/${env}`; // aboleyn Heroku endpoint
// export const endpoint = `http://localhost:8000/spaces/${space}/environments/${env}`; // aboleyn local endpoint

export const DataContext = React.createContext(); // Everything necessary to fetch the data

export function useIsMounted() { // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);
  return isMounted;
}

// Object.defineProperty(window, 'matchMedia', { // JSDOM polyfill
//     value: () => {
//         return {
//         matches: false,
//         addListener: () => {},
//         removeListener: () => {}
//         };
//     }
// });

// Object.defineProperty(window, 'getComputedStyle', {
//     value: () => {
//         return {
//         getPropertyValue: () => {}
//         };
//     }
// });
