///////////////////////////////////////////////////////////
// File        : DataBox.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./DataBox.scss";
import DataIconTop from "~/Components/Items/DataIconTop/DataIconTop";

// Function Definition
function DataBox(props) {
  // console.info("DataBox.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [items, setItems] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            if (fields.items && Array.isArray(fields.items)) {
              setItems(fields.items.map((item, index) => (
                <div className="item col-6 col-lg-3" key={item.sys.id}>
                  <DataIconTop id={item.sys.id} />
                </div>
              )));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="dataBox text-center">
      <div className="data-box mb-3">
        <div className="row">
          {items}
        </div>
      </div>
    </div>
  );
}

// Interface
DataBox.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
DataBox.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default DataBox;

