///////////////////////////////////////////////////////////
// File        : DataIconTop.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./DataIconTop.scss";

// Function Definition
function DataIconTop(props) {
  // console.info("DataIconTop.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [icon, setIcon] = useState("");
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            fields.icon && setIcon(fields.icon);
            fields.number && setNumber(fields.number);
            fields.description && setDescription(fields.description);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="dataIconTop">
      <div className="item-inner p-3 text-white">
        <div className={`icon dripicons-${icon}`}></div>
        <div className="number">{number}</div>
        <div className="desc">{description}</div>
      </div>
    </div>
  );
}

// Interface
DataIconTop.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
DataIconTop.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default DataIconTop;

