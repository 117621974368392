///////////////////////////////////////////////////////////
// File        : Workflow.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Workflow.scss";
import Logo from "~/LocalComponents/Items/Logo/Logo";

// Function Definition
function Workflow(props) {
  // console.info("Workflow.render");
  return (
    <svg width="800" height="600" fontFamily="sans-serif" className="workflow">
        <ellipse cx="50%" cy="50%" rx="300" ry="200" style={{fill: "#f8e9a1"}} />
        <ellipse cx="50%" cy="50%" rx="280" ry="180" className="white" />
        <svg x="25%" y="15%" width="120" height="120">
            <circle cx="50%" cy="50%" r="50%" className="circle1" />
            <text x="50%" y="40%" dominantBaseline="middle" textAnchor="middle" className="medium white">Query</text>
            <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" className="medium white">Engine</text>
        </svg>
        <svg x="60%" y="15%" width="120" height="120">
            <circle cx="50%" cy="50%" r="50%" className="circle2" />
            <text x="50%" y="40%" dominantBaseline="middle" textAnchor="middle" className="medium white">Workflow</text>
            <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" className="medium white">Engine</text>
        </svg>
        <svg x="70%" y="60%" width="120" height="120">
            <circle cx="50%" cy="50%" r="50%" className="circle3" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="medium white">Email</text>
        </svg>
        <svg x="15%" y="60%" width="120" height="120">
            <circle cx="50%" cy="50%" r="50%" className="circle4" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="medium white">Data</text>
        </svg>
        <svg x="340px" y="70%" width="120" height="120">
            <circle cx="50%" cy="50%" r="50%" className="circle5" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="medium white">WebSite</text>
        </svg>
        <svg x="40%" y="25%" width="20%" height="5%">
            <text x="50%" y="20%" dominantBaseline="middle" textAnchor="middle" className="medium black">&#8594;</text>
            <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" className="small black">segments</text>
        </svg>
        <svg x="60%" y="38%" width="20%" height="20%">
            <g transform="rotate(75,80,60)">
                <text x="50%" y="6" dominantBaseline="middle" textAnchor="middle" className="medium black">&#8594;</text>
                <text x="50%" y="18" dominantBaseline="middle" textAnchor="middle" className="small black">campaigns</text>
            </g>
        </svg>
        <svg x="54%" y="68%" width="20%" height="20%">
            <g transform="rotate(-15,80,60)">
                <text x="50%" y="6" dominantBaseline="middle" textAnchor="middle" className="medium black">&#8592;</text>
                <text x="50%" y="18" dominantBaseline="middle" textAnchor="middle" className="small black">reactions</text>
            </g>
        </svg>
        <svg x="26%" y="68%" width="20%" height="20%">
            <g transform="rotate(15,80,60)">
                <text x="50%" y="6" dominantBaseline="middle" textAnchor="middle" className="medium black">&#8592;</text>
                <text x="50%" y="18" dominantBaseline="middle" textAnchor="middle" className="small black">analytics</text>
            </g>
        </svg>
        <svg x="20%" y="38%" width="20%" height="20%">
            <g transform="rotate(-75,80,60)">
                <text x="50%" y="6" dominantBaseline="middle" textAnchor="middle" className="medium black">&#8594;</text>
                <text x="50%" y="18" dominantBaseline="middle" textAnchor="middle" className="small black">uploads</text>
            </g>
        </svg>
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="large" style={{fill: "#212624"}}>Life-Cycle</text>
        <ellipse cx="50%" cy="32%" rx="400" ry="150" style={{fill: "#f8e9a1", fillOpacity: "0.1"}} />
        <svg x="370px" y="8%"  width="60" height="60" viewBox="0 0 670 670">
            <Logo/>
        </svg>
        <svg x="40%" y="90%" width="20%" height="10%">
            <text x="50%" y="20%" dominantBaseline="middle" textAnchor="middle" className="medium black">&#8595;</text>
            <ellipse cx="50%" cy="64%" rx="40" ry="14" style={{fill: "#e94f08"}} />
            <text x="50%" y="64%" dominantBaseline="middle" textAnchor="middle" className="medium white">sales</text>
        </svg>
        <text x="50%" y="42%" dominantBaseline="middle" textAnchor="middle" className="large" style={{fill: "#212624"}}>eCRM</text>
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="large" style={{fill: "#212624"}}>Life-Cycle</text>
    </svg>
  );
}

// Interface
Workflow.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Workflow.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Workflow;

