///////////////////////////////////////////////////////////
// File        : QueryJSON.js
// Description : 

// Imports : 

import jsonpath from "jsonpath";

// Class Definition
export default
class QueryJSON {
// Attributes

// Constructor
  constructor(data) {
    this.data = data;
  }


// Operations
  Get(path) {
    // console.info(`jsonpath: ${path}`);
    const response = {data: jsonpath.value(this.data, path)};
    return Promise.resolve(response);
  }

  retrieveEndpoint(id) {
    return `$.entries[?(@.sys.type == "Entry" && @.sys.id == "${id}")]`;
  }

  retrieveAssetEndpoint(id) {
    return `$.assets[?(@.sys.type == "Asset" && @.sys.id == "${id}")]`;
  }

}

// Exports

