///////////////////////////////////////////////////////////
// File        : Index.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Index.scss";
import HeroWrapper from "~/Components/Layouts/HeroWrapper/HeroWrapper";
import Workflow from "~/LocalComponents/Items/Workflow/Workflow";
import BenefitsSection from "~/Components/Layouts/BenefitsSection/BenefitsSection";
import SectionWithCards from "~/Components/Layouts/SectionWithCards/SectionWithCards";
import CallToActionSection from "~/Components/Layouts/CallToActionSection/CallToActionSection";
import Footer from "~/Components/Layouts/Footer/Footer";

// Function Definition
function Index(props) {
  // console.info("Index.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [heroWrapper, setHeroWrapper] = useState("");
  const [benefits, setBenefits] = useState("");
  const [why, setWhy] = useState("");
  const [callToAction, setCallToAction] = useState("");
  const [footer, setFooter] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.heroWrapper && setHeroWrapper(<HeroWrapper id={fields.heroWrapper.sys.id} />);
            fields.benefits && setBenefits(<BenefitsSection id={fields.benefits.sys.id} />);
            fields.why && setWhy(<SectionWithCards id={fields.why.sys.id} />);
            fields.callToAction && setCallToAction(<CallToActionSection id={fields.callToAction.sys.id} />);
            fields.footer && setFooter(<Footer id={fields.footer.sys.id} />);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="index">
      {heroWrapper}
      <section className="workflow-section">
        <div className="container">
          <div className="qa site-tagline pt-5">
            <div className="q">Question:</div><div className="question">What is an 'eCRM'?</div>
            <div className="a">Answer:</div><div className="answer">It is simply a Customer Relationship Management platform, designed specifically for eCommerce. It allows you to drive the CRM life-cycle easily and efficiently, optimising the interaction with your customers to increase sales.</div>
          </div>
          <div className="wide-graphic">
            <Workflow/>
          </div>
          <div className="qa site-tagline pt-5">
            <div className="q">Question:</div><div className="question">How is our product different?</div>
            <div className="a">Answer:</div><div className="answer">Our <span className="italic">Query Engine</span> is the most powerful available, capable of analysing millions of customer records with sub-second response. The customer segments produced are then used by our <span className="italic">Workflow Engine</span> to drive any conceivable marketing campaign with total automation.</div>
          </div>
        </div>
      </section>
      {benefits}
      {why}
      {callToAction}
      {footer}
    </div>
  );
}

// Interface
Index.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Index.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Index;

