///////////////////////////////////////////////////////////
// File        : CardIconLeft.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./CardIconLeft.scss";

// Function Definition
function CardIconLeft(props) {
  // console.info("CardIconLeft.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  // const {icon, title, narrative, ...rest} = props;
  const {id} = props;
  const [icon, setIcon] = useState("");
  const [title, setTitle] = useState("");
  const [narrative, setNarrative] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            fields.icon && setIcon(fields.icon);
            fields.title && setTitle(fields.title);
            fields.narrative && setNarrative(fields.narrative);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="cardIconLeft">
      <div className="item-inner p-3 p-lg-5">
        <div className="media flex-column flex-md-row">
          <div className="icon-holder mr-3 mr-lg-5 mb-3">
            <div className={`icon dripicons-${icon}`}></div>
          </div>
          <div className="media-body cardIconLeftTitle">
            <h4>
              {title}
            </h4>
            <div className="desc mb-2 cardIconLeftNarrative" dangerouslySetInnerHTML={{__html: narrative.replace(/\\n/g, "<br/>")}} />
          </div>
        </div>
      </div>
    </div>
  );
}

// Interface
CardIconLeft.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // icon: PropTypes.string,
  // title: PropTypes.string,
  // narrative: PropTypes.string,
});
CardIconLeft.defaultProps = Object.assign({}, defaultComponentProps, {
  // icon: "question",
  // title: "The title goes here",
  // narrative: "The narrative goes here",
});

// Exports

export default CardIconLeft;

